@import '../../app/comon.function.scss';

dialog.contactform {
  top: 30%;
  opacity: 0;
  max-width: 450px;
  width: 100%;
  @include sp_base() {
    height: 100%;
  }
  > div.content {
    width: 100%;
    max-height: 100%;
    overflow-y: auto;
    h2 {
      text-align: center;
    }
    input[type="text"],
    input[type="email"],
    select, label, textarea {
      display: block;
      width: 100%;
      padding: 4px;
      font-size: 13pt;
    }
    textarea {
      resize: vertical;
      min-height: 10em;
    }
    span.error_msg {
      display: block;
      width: 100%;
      padding: 3px;
      text-align: center;
      background-color: orangered;
    }
    div.data_control {
      width: 100%;
      padding: 15px;
      text-align: center;
      > button {
        display: inline-block;
        padding: 4px 15px;
        font-size: 14pt;
        border-radius: 10px;
      }
      > button.submit {
        background-color: var( --primary-color );
        padding: 6px 17px;
        margin-right: 15px;
        color: white;
      }
      > button.close {
        border: 2px solid var( --border-color );
      }
    }
  }
}

dialog.contactform.show {
  top: 35%;
  opacity: 1;
  @include sp_base() {
    top: 50%;
  }
}