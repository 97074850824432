.button {
  font-size: 11pt;
}

.login-button {
  margin-right: 10px;
  padding: 3px 5px;
  border: 1px dotted white;
  background-color: transparent;
  transition: all .3s ease-in-out;
  &:hover {
    border: 1px dotted #003ac6;
    color: #003ac6;
  }
}

.logout-button {
  margin-right: 10px;
  padding: 3px 5px;
  border: 1px dotted white;
  background-color: transparent;
}

.register-button {
  margin-right: 10px;
  padding: 3px 14px;
  border: 1px solid #003ac6;
  background-color: #003ac6;
}