h2.heading2 {
  font-size: 20pt;
  margin-top: 20px;
  margin-bottom: 25px;
}

ul.category_list {
  display: flex;
  flex-flow: wrap;
  align-items: center;
  justify-content: left;
  gap: 10px;
  width: 100%;
  > li.category {
    flex-basis: auto;
    .link_wrap {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 10px;
      padding: 15px;
      transition: all .3s ease;
      &:hover {
        background-color: var( --background-secondary-color );
      }
    }
    span.icon {
      flex-basis: 50px;
      font-size: 18pt;
      span.image_wrap {
        display: block;
        width: 1.3em;
        height: 1.3em;
        position: relative;
        overflow: hidden;
        img {
          position: absolute;
          top: 50%; left: 50%;
          transform: translate( -50%, -50% );
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
    }
    span.name {
      flex-basis: auto;
      font-size: 13pt;
      white-space: nowrap;
    }
  }
}