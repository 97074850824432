@import '../../app/comon.function.scss';

div.products_wrap {
  position: absolute;
  top: 100%; right: 0;
  padding: 10px;
  background-color: var( --categories-dropdown-background );
  box-shadow: 0px 0px 39px -10px #777777;
  border-radius: 10px;
  z-index: 5;
  transition: opacity .3s ease;
  @include sp_base() {
    position: unset;
    top: unset; right: unset;
    display: block;
    width: 100%;
  }
  > ul.products {
    display: block;
    > li {
      display: block;
      padding: 5px;
      border-bottom: 1px dotted var( --border-color-secondary );
      transition: all .3s ease-in-out;
      &:hover {
        background-color: var( --primary-color );
      }
      > a.link_box {
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 10px;
        @include sp_base() {
          width: 100%;
        }
        > div.thumbnail {
          flex-basis: auto;
          @include sp_base() {
            display: none;
          }
          > span.wrapper {
            display: block;
            width: auto;
          }
        }
        > div.details {
          flex-basis: auto;
          @include sp_base() {
            flex-basis: 100%;
          }
          > span.title {
            display: block;
            margin-bottom: 4px;
            font-size: 12pt;
            width: 350px;
            @include sp_base() {
              width: 100%;
            }
          }
          > span.description {
            display: block;
            font-size: 9pt;
            width: 350px;
            @include sp_base() {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

div.products_wrap:not([inert]) {
  opacity: 1;
  width: auto;
  height: auto;
}

div.products_wrap[inert] {
  opacity: 0;
  overflow: hidden;
  width: 0;
  height: 0;
}