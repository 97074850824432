.common_base {
  > button {
    flex-basis: auto;
    flex-grow: 1;
    white-space: nowrap;
    font-size: 12pt;
    padding: 3px 5px;
    background-color: transparent;
    transition: all .3s ease;
    > span.flag {
      display: inline-block;
      width: 1em;
      height: 1em;
      position: relative;
      > .image {
        position: absolute;
        inset: 0;
        transform: translateY(2px);
        width: 100%;
        height: 100%;
      }
    }
    &:hover {
      background-color: var( --primary-color );
    }
  }
  > button.selected {
    background-color: var( --primary-color );
  }
}

div.lang_selector_mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px;
  background-color: var( --categories-dropdown-background );
  @extend .common_base;
}

div.lang_selector {
  position: absolute;
  top: 100%; right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px;
  background-color: var( --categories-dropdown-background );
  box-shadow: 0px 0px 39px -10px #777777;
  z-index: -1;
  opacity: 0;
  transition: all .3s ease;
  > button {
    flex-basis: auto;
    flex-grow: 1;
    white-space: nowrap;
    font-size: 12pt;
    padding: 3px 5px;
    background-color: transparent;
    transition: all .3s ease;
    > span.flag {
      display: inline-block;
      width: 1em;
      height: 1em;
      position: relative;
      > .image {
        position: absolute;
        inset: 0;
        transform: translateY(2px);
        width: 100%;
        height: 100%;
      }
    }
    &:hover {
      background-color: var( --primary-color );
    }
  }
  > button.selected {
    background-color: var( --primary-color );
  }
}

div.lang_selector.visible {
  z-index: 1;
  opacity: 1;
}