@import '../app/comon.function.scss';

.footer {
  //position: absolute;
  //left: 0; bottom: 0;
  display: block;
  align-self: flex-end;
  width: 100%;
  margin-top: 15px;
  padding: 10px 15px;
  background-color: var( --background-primary-color );
  > div.flex {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    align-items: center;
  }
  nav {
    flex-basis: 50%;
    @include sp_base() {
      flex-basis: 100%;
    }
    > ul {
      display: flex;
      flex-flow: wrap;
      justify-content: left;
      align-items: center;
      width: 100%;
      @include sp_base() {
        margin-bottom: 10px;
        justify-content: center;
      }
      > li {
        flex-basis: auto;
        padding: 3px 15px;
        @include sp_base() {
          padding: 3px 10px;
        }
      }
    }
  }
  small {
    flex-basis: 50%;
    font-size: 12pt;
    text-align: right;
    @include sp_base() {
      flex-basis: 100%;
      text-align: center;
    }
  }
}
/*
span.spacer {
  display: block;
  width: 100%;
  height: 60px;
  opacity: 0;
}*/