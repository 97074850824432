div.gallery_wrapper {
  display: block;
  position: relative;
  width: 100%;
  padding: 15px;
  > div.view_control {
    position: absolute;
    bottom: 100%;
    right: 0;
    padding: 10px 20px;
    display: flex;
    flex-flow: wrap;
    align-items: center;
    text-align: center;
    font-size: 15pt;
    input[type="file"] {
      opacity: 0;
      transform: scale(0);
      width: 0;
      height: 0;
    }
    label {
      padding: 5px 10px;
    }
    label.media_upload_label {
      color: white;
      font-size: 12pt;
      background-color: var( --primary-color );
      border-radius: 10px;
      padding: 5px 12px;
      cursor: pointer;
      &:active {
        background-color: rgb(114, 36, 187);
      }
    }
    select {
      padding: 5px 10px;
      border: none;
      border-bottom: 2px solid var( --input-border-underline );
      &:focus {
        outline: none;
      }
    }
    fieldset {
      display: inline-block;
      height: 34px;
      margin-left: 10px;
      legend {
        display: inline;
        clear: both;
        width: auto;
      }
      input {
        margin: 0 10px;
      }
    }
  }
  > div.view_control.block {
    position: unset;
    display: block;
    text-align: right;
  }
  > ul {
    display: flex;
    flex-flow: wrap;
    gap: 10px;
    > li {
      flex-basis: calc( 100% / 6 - 10px );
      > div.media_wrapper {
        width: 100%;
        aspect-ratio: 16 / 9;
        position: relative;
        cursor: zoom-in;
        overflow: hidden;
        &::before {
          content: '';
          background-color: transparent;
          position: absolute;
          top: 0; left: 0;
          width: 100%;
          height: 100%;
          transition: background .3s ease-in-out;
          z-index: 1;
        }
        &:hover::before {
          background-color: rgba(85, 85, 85, .3);
          backdrop-filter: blur( 7px );
        }
        > img {
          width: 100%;
          position: absolute;
          top: 50%; left: 50%;
          transform: translate( -50%, -50% ) scale(1);
          transition: all .3s ease-in-out;
          z-index: 0;
        }
        &:hover > img {
          transform: translate( -50%, -50% ) scale(1.2);
        }
        > span.name {
          position: absolute;
          display: block;
          width: 100%;
          left: 0; bottom: 0;
          padding: 5px;
          font-size: 13pt;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          z-index: 2;
        }
        > button.insert {
          display: block;
          position: absolute;
          top: 50%; left: 50%;
          transform: translate( -50%, -50% );
          border: 1px solid white;
          background-color: transparent;
          font-size: 14pt;
          padding: 3px 10px;
          z-index: 2;
          opacity: 0;
          transition: all .3s ease-in-out;
        }
        > button.delete {
          display: block;
          position: absolute;
          top: 0; right: 0;
          margin-top: 5px;
          margin-right: 5px;
          padding: 0;
          font-size: 11pt;
          background-color: gray;
          color: white;
          z-index: 2;
          opacity: 0;
          transition: all .3s ease-in-out;
        }
        &:hover > button.delete,
        &:hover > button.insert {
          opacity: 1;
        }
      }
    }
  }
  > div.media_get_more {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    > button {
      display: inline-block;
      padding: 5px 15px;
      border-radius: 15px;
      background-color: blueviolet;
      &:active {
        background-color: rgb(103, 32, 170);
      }
    }
  }
  > div.media_viewr_modal_back {
    display: block;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0; left: 0;
    opacity: 0;
    z-index: -10;
    background-color: transparent;
    transition: all .4s ease-in-out;
    > div.media_viewr_modal {
      position: fixed;
      top: 50%; left: 50%;
      width: 85vw;
      aspect-ratio: 16 / 9;
      transform: scale(.9) translate( -50%, -35% );
      background-color: hsl(0deg 0% 12.75% / 80%);
      backdrop-filter: blur( 10px );
      transition: all .4s ease-in-out;
      z-index: -9;
      padding: 30px;
      > div.media_view {
        display: block;
        width: 100%;
        aspect-ratio: 16 / 9;
        overflow-y: auto;
        > img {
          display: block;
          max-width: 100%;
          margin: 0 auto;
        }
      }
      > button.close {
        position: absolute;
        top: -5px; left: 6px;
        font-size: 22pt;
      }
    }
  }
  > div.media_viewr_modal_back.show {
    opacity: 1;
    z-index: 5;
    > div.media_viewr_modal {
      z-index: 6;
      transform: scale(1) translate( -50%, -50% );
    }
  }
}