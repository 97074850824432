@import '../../app/comon.function.scss';

.cat_tree_common {  // extend class
  > ul.cate_tree {
    transition: all .3s ease-in-out;
    ul.sub_tree {
      flex-basis: auto;
      padding: 10px;
    }
    li {
      display: block;
      .cat_link {
        display: block;
        padding: 6px 6px;
        padding-left: 15px;
        transition: all .3s ease;
        &:hover {
          background-color: var( --primary-color );
        }
      }
      div.cat_record {
        display: block;
        white-space: nowrap;
        span.icon {
          flex-basis: auto;
          position: relative;
          margin-right: 10px;
          > img {
            position: absolute;
            top: 50%; left: 50%;
            transform: translate(-50%, -50%);
            width: 1.5em;
            height: 1.5em;
            border-radius: 50%;
          }
          .fontawesome {
            font-size: 1em;
            transform: translateX(-50%);
          }
        }
        span.name {
          flex-basis: auto;
          flex-grow: 1;
          white-space: nowrap;
        }
      }
    }
  }
}

div.cate_tree_wrap {
  position: absolute;
  top: 100%; right: 0;
  max-width: 80vw;
  max-height: 80vh;
  overflow: auto;
  padding: 10px;
  background-color: var( --categories-dropdown-background );
  backdrop-filter: blur( 10px );
  box-shadow: 0px 0px 39px -10px #777777;
  border-radius: 10px;
  z-index: -1;
  opacity: 0;
  transition: all .3s ease-in-out;
  @include sp_base() {
    position: fixed;
    right: 100vw;
    width: 100vw;
    height: 100vh;
    transform-origin: left;
  }
  @extend .cat_tree_common;
}

div.cate_tree_wrap.show {
  z-index: 1;
  opacity: 1;
  @include sp_base() {
    transform: translateX(100%);
  }
}

div.cate_tree_wrap_mobile {
  width: 100%;
  overflow: auto;
  @extend .cat_tree_common;
}