@import "../app/comon.function.scss";

.admin_toolbar {
  width: 100vw;
  position: fixed;
  display: flex;
  flex-direction: row;
  padding: 4px;
  font-size: 16px;
  //height: 24px;
  background-color: var(--admin-bar-background);
  z-index: 500;
  > .tool_links {
    flex-basis: 80%;
    display: flex;
    flex-flow: wrap;
    ul {
      display: flex;
      flex-flow: wrap;
      gap: 15px;
      li {
        flex-basis: auto;
        a {
          color: rgb(var(--foreground-color));
          padding: 0 15px;
        }
      }
    }
  }
  > .status_box {
    flex-basis: 20%;
  }
}

.header {
  flex-basis: 100%;
  display: flex !important;
  align-items: center;
  width: 100vw;
  padding: 10px;
  @include sp_base() {
    flex-direction: unset;
    flex-flow: wrap;
  }
}

.header.toolbar_enable {
  margin-top: 26px;
}

span.toolbar_enable {
  display: block;
  width: 100%;
  height: 70px;
}

div.menubar_mobile {
  display: none;
  flex-basis: auto;
  font-size: 18pt;
  margin-right: 15px;
  @include sp_base() {
    display: block;
  }
}

.siteTitle {
  flex-basis: auto;
  .text {
    font-size: 20pt;
  }
  h1 {
    font-size: 20pt;
  }
}

nav.navigations {
  flex-basis: auto;
  flex-grow: 1;
  padding: 0 15px;
  @include sp_base() {
    display: none;
  }
  > ul {
    display: block;
    width: 100%;
    background-color: var( --background-primary-color );
    > li {
      float: right;
      padding: 5px 15px;
      @include sp_base() {
        clear: both;
        flex-basis: 100%;
        text-align: left;
        flex-grow: 1;
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 13t;
      }
      button {
        color: var( --foreground-color );
        white-space: nowrap;
        .default {
          font-size: 1em;
          font-display: swap;
        }
      }
    }
  }
}

div.author_sns {
  flex-basis: auto;
  text-align: center;
  @include sp_base() {
    text-align: right;
    align-self: center;
    flex-grow: 1;
  }
  a {
    font-size: 12pt;
    margin: 0 5px;
    @include sp_base() {
      font-size: 15pt;
    }
  }
}