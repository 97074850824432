div.wrapper {
  align-self: flex-start;
  display: block;
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  margin-top: 70px;
  position: relative;
  h2 {
    span.status {
      display: inline-block;
      margin-left: 20px;
      font-size: 12pt;
    }
  }
  > div.container {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    > div.edit_control {
      max-width: 1000px;
      width: 100%;
      padding: 10px;
    }
    > div.widgets {
      //flex-basis: 400px;
      width: 400px;
      //flex-grow: 1;
      align-self: start;
      padding: 10px;
      //max-height: calc( 100vh - 34px );
      display: flex;
      flex-direction: column;
      background-color: var( --ck-code-block-background-color );
      h3 { font-size: 17pt; }
      > div.thumbnail {
        display: block;
        width: 100%;
        position: relative;
        > div.selector {
          display: block;
          width: 100%;
          aspect-ratio: 16 / 9;
          position: relative;
          overflow: hidden;
          > span.no_thumb_text, > img,
          > button.select_btn {
            position: absolute;
            top: 50%; left: 50%;
            transform: translate( -50%, -50% );
            z-index: 2;
          }
          > span.no_thumb_text {
            font-size: 15pt;
            text-align: center;
            z-index: 0;
          }
          > img {
            width: 100%;
            z-index: 1;
          }
          > button.select_btn {
            display: block;
            width: 100%;
            height: 100%;
            font-size: 12pt;
            opacity: 0;
          }
        }
        button.delete {
          position: absolute;
          top: -4px; right: 0;
          padding: 3px 10px;
          font-size: 12pt;
          color: red;
          border: 1px solid red;
          z-index: 1;
        }
        dialog > div.thumb_select_dialog {
          display: flex;
          flex-direction: column;
          max-width: calc( 100vw - 5% );
          max-height: calc( 100vh - 80px );
          width: 100%;
          height: 100%;
          position: fixed;
          top: 50%; left: 50%;
          transform: translate( -50%, -40% );
          padding: 15px;
          background-color: var( --background-secondary-color );
          border-radius: 10px;
          opacity: 0;
          overflow: hidden;
          transition: all .4s ease-in-out;
          > h3 {
            width: 100%;
            align-self: flex-start;
            text-align: left;
            border-bottom: 1px dotted var( --border-color-secondary );
            margin: 0;
            padding-bottom: 10px;
          }
          > button.close {
            display: block;
            position: absolute;
            font-size: 14pt;
            top: 14px; right: 24px;
          }
          > div.gallery_component {
            //position: relative;
            flex-basis: 100%;
            //height: calc( 100% - 15px );
            overflow-y: scroll;
          }
        }
        dialog[open] {
          top: 50%; left: 50%;
          transform: translate(-50%, -50%);
          max-width: calc( 100vw - 5% );
          max-height: calc( 100vh - 80px );
          width: 100%;
          height: 100%;
          > div.thumb_select_dialog {
            opacity: 1;
            transform: translate( -50%, -50% );
          }
        }
      }
      > div.permalink {
        display: block;
        width: 100%;
        margin-top: 15px;
        margin-bottom: 15px;
        > div.input {
          width: 100%;
          margin-top: 18px;
          > span {
            display: inline-block;
            width: 5%;;
            font-size: 15pt;
            padding: 3px 0;
          }
          > input[type="text"] {
            display: inline-block;
            width: 94%;
            font-size: 15pt;
            padding: 3px 5px;
            border-bottom: 2px solid var(--ck-color-input-border);
            background-color: var(--ck-custom-background);
            &:focus {
              border-bottom: 2px solid var(--ck-color-widget-type-around-button-active);
            }
          }
        }
      }
      > div.post_type {
        display: block;
        width: 100%;
        margin-top: 15px;
        margin-bottom: 15px;
        > div.input {
          display: block;
          width: 100%;
          > span.radio {
            display: inline-block;
            margin-right: 10px;
            //position: relative;
            > input {
              width: 0;
              height: 0;
            }
            > label {
              padding: 5px 15px;
              font-size: 13pt;
              border: 1px solid var( --border-color );
              border-radius: 15px;
              cursor: pointer;
            }
            > input[type="radio"]:checked + label {
              background-color: var( --primary-color );
            }
          }
        }
      }
      > div.category {
        display: block;
        width: 100%;
        margin-top: 15px;
        > div.tree_view {
          display: block;
          width: 100%;
          max-height: calc( 100vh - 370px );
          margin-top: 18px;
          overflow: auto;
          ul.category_group {
            display: block;
            padding-left: 20px;
            position: relative;
            &::before {
              position: absolute;
              top: 1em;
              left: 5px;
              content: '';
              width: 1px;
              height: calc( 100% - 1em );
              background-color: var( --border-color );
            }
            li {
              display: block;
              white-space: nowrap;
              padding: 5px;
              font-size: 14pt;
              cursor: default;
              transition: all .3s;
              > div.item {
                display: inline-block;
                padding-left: 10px;
                padding-right: 10px;
                position: relative;
                &::before {
                  content: '';
                  display: block;
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  top: 0; left: 0;
                  background-color: transparent;
                  //transition: all .2s;
                  z-index: -2;
                }
                label.select_cat_label {
                  display: block;
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  top: 0; left: 0;
                  cursor: pointer;
                  color: transparent;
                  background-color: transparent;
                  z-index: -1;
                }
                input[type="checkbox"] {
                  display: block;
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  top: 0; left: 0;
                  cursor: pointer;
                  opacity: 0;
                  z-index: 1;
                }
                > span.icon {
                  width: 1.2em;
                  height: 1.2em;
                  position: relative;
                  //margin-left: 20px;
                  margin-right: 10px;
                  display: inline-block;
                  border-radius: 50%;
                  text-align: center;
                  transform: translateY(0.2em);
                  overflow: hidden;
                  img {
                    position: absolute;
                    top: 50%; left: 50%;
                    transform: translate( -50%, -50% );
                    width: 100%;
                  }
                }
              }
              > div.item.bg_color::before {
                background-color: var( --background-secondary-color );
              }
            }
          }
        }
      }
    }
  }
  div.post_push {
    position: absolute;
    top: -5px; right: 0;
    display: flex;
    flex-flow: wrap;
    gap: 20px;
    z-index: 1;
    button {
      font-size: 12pt;
      padding: 4px 15px;
      border-radius: 10px;
    }
    button.draft {
      color: rgb( var( --foreground-color ) );
      background-color: transparent;
      border: 1px dotted rgb( var( --foreground-color ) );
    }
    button.publish, button.update {
      background-color: var( --primary-color );
    }
  }
}